.nounderline{text-decoration: none !important}
.blue{
    color: #0087d8;
}
.green{
    color: #51a04d;
}
.grey{
    color: #cbcbcb;
}
.white{
    color: #fff;
}
.yellow{
  color: #e4a91f;
}
.blue_bg{
    background-color:#0087d8;
}
.green_bg{
    background-color:#51a04d;
}
.grey_bg{
    background-color:#cbcbcb;
}
.white_bg{
  background-color:#fff;
}
.yellow_bg {
  background-color: #e4a91f;
}
.pointer {cursor: pointer;}
button {outline: 0}
a.white:hover {
    color: #fff;
}
a {text-decoration: none !important}
.border_grey {
  border-color: #cbcbcb;
}
.no_resize {
  resize: none;
}
.underline {
  text-decoration: underline;
}
.w-10 {width: 10%;}
.w-20 {width: 20%;}
.w-90 {width: 90%;}
.w-80 {width: 80%;}
.w-60 {width: 60%;}
.w-70 {width: 70%;}
.w-40 {width: 40%;}
.w-85 {width: 85%;}
.w-15 {width: 15%;}
.w-35 {width: 35%;}
.w-30 {width: 30%;}
.w-95 {width: 95%;}
.center-abs {
  position: absolute;
  border: 3px solid #cbcbcb;
  border-top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  width: 465px;
  max-height: 500px;
  overflow: scroll;
  margin-top: 11px;
  -webkit-box-shadow: 0px 0px 15px -1px rgba(179,179,179,1);
  -moz-box-shadow: 0px 0px 15px -1px rgba(179,179,179,1);
  box-shadow: 0px 0px 15px -1px rgba(179,179,179,1);
}
hr {
  border-width: 2px;
  border-color: #cbcbcb;
}
input:focus {color: #0087d8!important}
.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #e4a91f!important;
}
@media (max-width: 760px) {
  .center-abs{
    position: fixed;
  }
}
@media (max-width: 1060px) {
  .center-abs{
    width: 360px;
  }
}
@media (max-width: 767px) {
  .center-abs{
    margin-top: 24px;
  }
}
@media (max-width: 500px) {
  .center-abs{
    font-weight: lighter;
  }
  .center-abs ul.list-group li.lq_row {
    font-weight: lighter;
  }
  .center-abs ul.list-group li.lq_row h5 {
    font-size: 85%;
  }
}
@media (max-width: 360px) {
  .center-abs{
    width: 90vw;
  }
}

/* Fixing left */
@media (min-width: 614px) and (max-width: 760px) {
  .center-abs{
    left: 30%;
  }
}
@media (min-width: 460px) and (max-width: 614px) {
  .center-abs{
    left: 40%;
  }
}
@media (max-width: 767px){
.center-abs {
    margin-top: 15px;
  }
}
@media screen and (min-width: 320px) and (max-width: 478px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
